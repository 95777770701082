import React, { Component } from 'react';

//Style
import { CardContainer, Card } from './select-vitrine-styled';

export default class SelectVitrine extends Component {
    render(){
        return(
            <CardContainer>
                <Card color="#00bcd4" href={process.env.URL_VITRINE_IMOVEIS}>
                    Imóveis
                </Card>

                <Card color="#cf2429" href={process.env.URL_VITRINE_CARROS}>
                    Carros
                </Card>

                <Card color="#940057" href={process.env.URL_VITRINE_MOTOS}>
                    Motos
                </Card>

                <Card color="#0b9140" href={process.env.URL_VITRINE_AGRO}>
                    Agro
                </Card>

                <Card color="#ffa000" href={process.env.URL_VITRINE_NEGOCIOS}>
                    Negócios
                </Card>

                <Card color="#f07226" href={process.env.URL_VITRINE_EMPREGOS}>
                    Empregos
                </Card>
            </CardContainer>
        )
    }
}