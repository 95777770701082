import styled from 'styled-components';

export const HamburguerContainer = styled.div`
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all .2s ease-out;

    margin-left: 10px;
    margin-right: 20px;
`;

export const Stripe = styled.div`
    width: 100%;
    height: 5px;
    background: white;
    margin: 4px auto;
    transition: all 0.3s;
    backface-visibility: hidden;
`;

export const StripeTop = styled(Stripe)`
    transform: ${ props => props.open ? 'rotate(45deg) translate(6px, 6px)' : null }
`;

export const StripeMiddle = styled(Stripe)`
    opacity: ${ props => props.open ? '0' : null };
`;

export const StripeBottom = styled(Stripe)`
    transform: ${ props => props.open ? 'rotate(-45deg) translate(6px, -7px)' : null }
`;

