import styled from 'styled-components';

export const Logo = styled.img`
    width: 300px;
`

export const ErroContent = styled.div`
    padding: 50px 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Titulo = styled.p`
    font-size: 120px;
    font-weight: bold;

    color: #FFF;

    margin-top: 20px;
    margin-bottom: 0;
`

export const Subtitulo = styled.p`
    font-size: 28px;
    font-weight: bold;
    color: #FFF;
    margin-top: 0;
    margin-bottom: 20px;
`