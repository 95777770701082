import React, { useEffect } from "react";

// Components
import Topbar from "../components/topbar/topbar";
import Filter from "../components/filter/filter";
import Card from "../components/cards/card";
import { CardContainer, LoadMoreBtn } from "../components/cards/card-styled";

import Copyright from "../components/copyright/copyright";
import { useSelector, useDispatch } from "react-redux";
import { fetchGaragemLayout } from "../../../actions";
import { FETCH_SEARCH_OBJECT } from "../../../actions/types";
import { Helmet } from "react-helmet";

import { buildUrl } from "../components/image-render/image-render";

const Estoque = () => {
  const dispatch = useDispatch();

  const siteId = useSelector((state) => state.template.siteId, []);
  const layout = useSelector((state) => state.template.layout, []);
  const anuncios = useSelector((state) => state.busca.anuncios);
  const searchObj = useSelector((state) => state.busca.searchObj);
  const filtros = useSelector((state) => state.busca.filtros);

  const loadMoreAnuncios = () => {
    if (filtros.qtdPaginas > filtros.paginaAtual) {
      dispatch({
        type: FETCH_SEARCH_OBJECT,
        payload: { ...searchObj, pagina: filtros.paginaAtual + 1 },
      });
    }
  };

  const getFavIcon = (size, logoUrl) => {
    return buildUrl({
      src: logoUrl,
      width: size,
      height: size,
      resizeType: "fit",
    });
  };

  useEffect(() => {
    if (layout && !layout.template) {
      dispatch(fetchGaragemLayout({ hostName: window.location.hostname }));
    }
  }, []);

  const getCardTypeByTemplate = (template) => {
    switch (template) {
      case "lista":
        return "card-full-width";
      case "card":
        return "card-mini";
      case "ticket":
        return "card-vertical";
    }
  };

  return (
    <>
      {layout && (
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            href={getFavIcon("32", layout.logo)}
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href={getFavIcon("76", layout.logo)}
          />
        </Helmet>
      )}
      <Topbar
        type={layout.template === "lista" ? "simple" : "with-logo"}
        backgroundColor={layout.cor_principal}
        textColor={layout.cor_texto}
        textColorActive={layout.cor_texto_ativo}
        logo={layout.logo}
        bannerDesktop={layout.banner_desktop}
        bannerMobile={layout.banner_mobile}
      />
      <br />
      <Filter type="compact" backgroundColor={layout.cor_principal} />

      <CardContainer>
        {anuncios &&
          anuncios.map((anuncio) => (
            <Card
              type={getCardTypeByTemplate(layout.template)}
              defaultImg={layout.logo}
              defaultColor={layout.cor_principal}
              info={anuncio}
              key={anuncio.id}
            />
          ))}

        {filtros && filtros.qtdPaginas > filtros.paginaAtual + 1 && (
          <LoadMoreBtn
            backgroundcolor={layout.cor_secundaria}
            textcolor={layout.cor_texto_botao}
            variant="outlined"
            onClick={loadMoreAnuncios}
          >
            Carregar mais
          </LoadMoreBtn>
        )}
      </CardContainer>
      <br />
      <Copyright
        backgroundColor={layout.cor_principal}
        textColor={layout.cor_texto}
      />
    </>
  );
};

export const loadData = ({ store, match, req }) => {
  const fullUrl = req.get("host");
  const nome = req.originalUrl.split("/")[1] || null;

  return store.dispatch(fetchGaragemLayout({ hostName: fullUrl, nome: nome }));
};

export default Estoque;
