import React, { Component } from "react";

//Styles
import { Container, Card, Titulo, SlideText } from "./select-anuncie-styled";

// Images
import ImovelVendaSVG from "./../../../../resources/svg/vitrines/logo-imovel-venda.svg";
import ImovelAluguelSVG from "./../../../../resources/svg/vitrines/logo-imovel-aluguel.svg";
import CarrosSVG from "./../../../../resources/svg/vitrines/logo-carros.svg";
import MotosSVG from "./../../../../resources/svg/vitrines/logo-motos.svg";
import AgroSVG from "./../../../../resources/svg/vitrines/logo-agro.svg";
import NegociosSVG from "./../../../../resources/svg/vitrines/logo-negocios.svg";
import EmpregosSVG from "./../../../../resources/svg/vitrines/logo-empregos.svg";

export default class SelectAnuncie extends Component {
  openSection = (section) => {
    switch (section) {
      case "imovel-venda":
        window.open(process.env.URL_ANUNCIE_IMOVEL_VENDA, "_self");
        break;

      case "imovel-aluguel":
        window.open(process.env.URL_ANUNCIE_IMOVEL_ALUGUEL, "_self");
        break;

      case "carros":
        window.open(process.env.URL_ANUNCIE_CARROS, "_self");
        break;

      case "motos":
        window.open(process.env.URL_ANUNCIE_MOTOS, "_self");
        break;

      case "agro":
        window.open(process.env.URL_ANUNCIE_AGRO, "_self");
        break;

      case "negocios":
        window.open(process.env.URL_ANUNCIE_NEGOCIOS, "_self");
        break;

      case "empregos":
        window.open(process.env.URL_ANUNCIE_EMPREGOS, "_self");
        break;
    }
  };

  render() {
    return (
      <Container>
        <Card
          color="#4a90e2"
          onClick={() => this.openSection("imovel-venda")}
          icon={ImovelVendaSVG}
        >
          <Titulo color="#4a90e2">Imóvel venda</Titulo>
          <SlideText color="#4a90e2" className="slide-text">
            Anuncie!
          </SlideText>
        </Card>

        <Card
          color="#04bcd5"
          onClick={() => this.openSection("imovel-aluguel")}
          icon={ImovelAluguelSVG}
        >
          <Titulo color="#04bcd5">Imóvel aluguel</Titulo>
          <SlideText color="#04bcd5" className="slide-text">
            Anuncie!
          </SlideText>
        </Card>

        <Card
          color="#d0021b"
          onClick={() => this.openSection("carros")}
          icon={CarrosSVG}
        >
          <Titulo color="#d0021b">Carros</Titulo>
          <SlideText color="#d0021b" className="slide-text">
            Anuncie!
          </SlideText>
        </Card>

        <Card
          color="#940057"
          onClick={() => this.openSection("motos")}
          icon={MotosSVG}
        >
          <Titulo color="#940057">Motos</Titulo>
          <SlideText color="#940057" className="slide-text">
            Anuncie!
          </SlideText>
        </Card>

        <Card
          color="#009645"
          onClick={() => this.openSection("agro")}
          icon={AgroSVG}
        >
          <Titulo color="#009645">Agro</Titulo>
          <SlideText color="#009645" className="slide-text">
            Anuncie!
          </SlideText>
        </Card>

        <Card
          color="#ea8b1c"
          onClick={() => this.openSection("negocios")}
          icon={NegociosSVG}
        >
          <Titulo color="#ea8b1c">Negócios</Titulo>
          <SlideText color="#ea8b1c" className="slide-text">
            Anuncie!
          </SlideText>
        </Card>

        <Card
          color="#f05a22"
          onClick={() => this.openSection("empregos")}
          icon={EmpregosSVG}
        >
          <Titulo color="#f05a22">Empregos</Titulo>
          <SlideText color="#f05a22" className="slide-text">
            Anuncie!
          </SlideText>
        </Card>
      </Container>
    );
  }
}

