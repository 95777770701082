import React, { useState, useEffect } from "react";
import {
  ContatoContainer,
  ContatoButton,
  ContatoForm,
  ContatoInput,
  ContatoSelect,
  ContatoBtn,
  ContatoMensagemContainer,
  ContatadoTexto,
  ContatoIcon,
} from "./contato-styled";
import { Formik } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Hidden,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Reaptcha from "reaptcha";
import { sendContato } from "./../../../../actions";

// Icons
import WhatsappIcon from "./../../../../resources/svg/whatsapp.svg";
import PhoneIcon from "./../../../../resources/svg/phone.svg";

const Contato = ({
  anuncioId,
  textColor,
  buttonColor,
  telefoneList,
  titulo,
  whatsapp,
}) => {
  const dispatch = useDispatch();

  const siteId = useSelector((state) => state.template.siteId, []);

  const [contatado, setContatado] = useState(false);
  const [openPhoneList, setOpenPhoneList] = useState(false);
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const [msg, setMsg] = useState();

  useEffect(() => {
    setMsg(`Desejo saber mais sobre o anúncio ${titulo}`);
  }, []);

  const handleOpenPhone = () => {
    setOpenPhoneList(true);
  };

  return (
    <>
      <ContatoContainer>
        {whatsapp && (
          <ContatoButton
            component="a"
            target="_blank"
            href={`https://wa.me/55${whatsapp}?text=${msg}`}
            variant="outlined"
            className="whatsapp"
          >
            <ContatoIcon src={WhatsappIcon} />
            Enviar mensagem
          </ContatoButton>
        )}
        {telefoneList && (
          <ContatoButton
            variant="outlined"
            className="phone"
            onClick={handleOpenPhone}
          >
            <ContatoIcon src={PhoneIcon} />
            Ligar para loja
          </ContatoButton>
        )}

        <Dialog open={openPhoneList}>
          <DialogTitle>Telefones</DialogTitle>
          <DialogContent>
            {/* Mobile */}
            <Hidden smUp>
              <DialogContentText>
                {telefoneList &&
                  telefoneList.map((number) => (
                    <>
                      <a href={`tel:+55${number.numero}`}>
                        {number.numeroFormatado}
                      </a>
                      <br />
                    </>
                  ))}
              </DialogContentText>
            </Hidden>

            {/* Desktop */}
            <Hidden smDown>
              <DialogContentText>
                {telefoneList &&
                  telefoneList.map((number) => <p>{number.numeroFormatado}</p>)}
              </DialogContentText>
            </Hidden>
            <DialogActions>
              <Button onClick={() => setOpenPhoneList(false)}>Fechar</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {!contatado && (
          <Formik
            initialValues={{ idAnuncio: anuncioId }}
            enableReinitialize
            onSubmit={(values) => {
              dispatch(sendContato(values).then(setContatado(true)));
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <ContatoForm>
                <ContatoInput
                  label="Nome"
                  name="nome"
                  variant="outlined"
                  value={values.nome}
                  onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                />
                <ContatoSelect variant="outlined">
                  <InputLabel>Quero ser contatado via:</InputLabel>
                  <Select
                    name="tipoContatoMensagem"
                    onChange={(e) =>
                      setFieldValue([e.target.name], e.target.value)
                    }
                  >
                    <MenuItem value="EMAIL">E-mail</MenuItem>
                    <MenuItem value="TELEFONE">Telefone</MenuItem>
                    <MenuItem value="WHATSAPP">Whatsapp</MenuItem>
                  </Select>
                </ContatoSelect>
                {values.tipoContatoMensagem && (
                  <ContatoInput
                    label="Contato (e-mail ou whatsapp)"
                    name={values.tipoContatoMensagem.toLowerCase()}
                    variant="outlined"
                    value={values.contato}
                    onChange={(e) =>
                      setFieldValue(e.target.name, e.target.value)
                    }
                  />
                )}
                <ContatoInput
                  label="Mensagem"
                  name="texto"
                  variant="outlined"
                  multiline
                  value={values.mensagem}
                  onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                />

                <Reaptcha
                  className="captcha"
                  sitekey="6LcOEfoUAAAAAAcXf-8ftx8R-1COAXU90k_S5hXU"
                  onVerify={(response) => {
                    setVerifiedCaptcha(true);
                    setFieldValue("captcha", response);
                  }}
                />

                <ContatoBtn
                  disabled={!verifiedCaptcha}
                  textColor={textColor}
                  buttonColor={buttonColor}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Enviar
                </ContatoBtn>
              </ContatoForm>
            )}
          </Formik>
        )}
        {contatado && (
          <ContatoMensagemContainer>
            <ContatadoTexto>Mensagem enviada!</ContatadoTexto>
          </ContatoMensagemContainer>
        )}
      </ContatoContainer>
    </>
  );
};

export default Contato;

