import React, { Component } from 'react';

//Components
import Hidden from '@material-ui/core/Hidden';

//Styles
import { FooterContainer, Line, Logo, LogoContainer, SocialContainer, UrlList, UrlListItem, InformacaoContainer, Titulo, SimpleText, Icon, Phone } from './footer-styled';

//Svg
import LogoClassi from './../../../../resources/svg/logo-classi.svg';
import Facebook from './../../../../resources/svg/facebook-logo.svg';
import Twitter from './../../../../resources/svg/twitter.svg';
import { SocialButton } from '../commons/theme-styled';

export default class Footer extends Component {

    // Instana Script Injection
    componentDidMount() {
        // Inject inline script
        const inlineScript = document.createElement("script");
        inlineScript.type = "text/javascript";
        inlineScript.innerHTML = `
          (function(s,t,a,n){s[t]||(s[t]=a,n=s[a]=function(){n.q.push(arguments)},
          n.q=[],n.v=2,n.l=1*new Date)})(window,"InstanaEumObject","ineum");

          ineum('reportingUrl', 'https://eum-red-saas.instana.io');
          ineum('key', 'HKUPasl6Qt2ExCSimHrIWg');
          ineum('trackSessions');
        `;
        document.head.appendChild(inlineScript);

        // Inject external script
        const externalScript = document.createElement("script");
        externalScript.defer = true;
        externalScript.crossOrigin = "anonymous";
        externalScript.src = "https://eum.instana.io/1.7.2/eum.min.js";
        externalScript.integrity =
          "sha384-cgeSlevgebehPauohUhsnAeBrpjXzaj94mSv3L2EXjCQH0RRb9xSQ2ErGOWkthIJ";
        document.head.appendChild(externalScript);
    }

    render() {
        return (
            <FooterContainer>
                <LogoContainer>
                    <Logo src={LogoClassi} />

                    <SocialContainer>
                        <SocialButton variant="outlined">
                            <Icon src={Facebook} />
                        </SocialButton>

                        <SocialButton variant="outlined">
                            <Icon src={Twitter} />
                        </SocialButton>
                    </SocialContainer>
                </LogoContainer>

                <Hidden xsDown>
                    <UrlList>
                        <Titulo>
                            Classificados
                        </Titulo>

                        <UrlListItem as="a" href={process.env.URL_ANUNCIE}>Anuncie</UrlListItem>
                        <UrlListItem as="a" href={process.env.URL_VITRINE_AGRO}>Agro</UrlListItem>
                        <UrlListItem as="a" href={process.env.URL_VITRINE_CARROS}>Carros</UrlListItem>
                        <UrlListItem as="a" href={process.env.URL_VITRINE_IMOVEIS}>Imóveis</UrlListItem>
                        <UrlListItem as="a" href={process.env.URL_VITRINE_EMPREGOS}>Empregos</UrlListItem>
                        <UrlListItem as="a" href={process.env.URL_VITRINE_MOTOS}>Motos</UrlListItem>
                        <UrlListItem as="a" href={process.env.URL_VITRINE_NEGOCIOS}> Negócios</UrlListItem>
                        <UrlListItem as="a" href="https://jaimecamaradigital.com.br/anuncie/">Mídia Kit Online</UrlListItem>
                        <UrlListItem as="a" href="https://jaimecamaradigital.com.br/anuncie/">Mídia Kit Impresso</UrlListItem>
                    </UrlList>

                    <UrlList>
                        <Titulo>
                            Grupo Jaime Câmara
                        </Titulo>

                        <UrlListItem as="a" href={process.env.URL_OPOPULAR}>O Popular</UrlListItem>
                        <UrlListItem as="a" href={process.env.URL_DAQUI}>Jornal Daqui</UrlListItem>
                        <UrlListItem as="a" href={process.env.URL_LUDOVICA}>Ludovica</UrlListItem>
                        <UrlListItem as="a" href={process.env.URL_CLUBE_OPOPULAR}>Clube O Popular</UrlListItem>
                        <UrlListItem as="a" href={process.env.URL_CBN}>CBN</UrlListItem>
                    </UrlList>

                    <InformacaoContainer>
                        <Titulo color="#e7891a">
                            Fale Conosco
                            <br />
                        </Titulo>
                        <Phone color="#e7891a">
                            62 3250.5323
                        </Phone>

                        <SimpleText>
                            Todas as regras e promoções são válidas apenas para produtos vendidos e entregues pelo classi.com.br. O preço válido será o da finalização da compra. J. Câmara e Imãos S/A / CNPJ: 01.536.754/0001-23 / Endereço: Rua Thomas Edison, nº 400, Qd 07 - Setor Serrinha - Goiânia, GO - CEP: 74.835-130 / Atendimento ao cliente: atendimento@classi.com.br
                        </SimpleText>
                    </InformacaoContainer>
                </Hidden>
            </FooterContainer>
        );
    }

}
