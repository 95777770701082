import React, { useEffect, useState } from "react";
import Topbar from "../components/topbar/topbar";
import Unidades from "../components/unidades/unidades";
import Copyright from "../components/copyright/copyright";
import { useSelector, useDispatch } from "react-redux";
import { fetchGaragemLayout, fetchAnuncioById } from "../../../actions";
import {
  AnuncioContainer,
  AnuncioInfo,
  AnuncioTitleContainer,
  AnuncioResumo,
  AnuncioSection,
  SectionTitle,
  SectionText,
  SectionList,
  ResumoContainer,
} from "./Anuncio-styled";
import {
  getParams,
  getClientSiteName,
} from "../../../resources/helpers/getParams";
import Gallery from "../components/gallery/gallery";
import Contato from "../components/contato/contato";
import { Hidden } from "@material-ui/core";
import RedesSociais from "../components/redes-sociais/redesSociais";
import Currency from "react-currency-formatter";
import { Helmet } from "react-helmet";
import { buildUrl } from "../components/image-render/image-render";

const getLastArrayPosition = (array) => {
  if (array == null) return 0;

  return array[array.length - 1];
};

const Anuncio = () => {
  const [anuncioId, setAnuncioId] = useState();
  const dispatch = useDispatch();

  const siteId = useSelector((state) => state.template.siteId, []);
  const layout = useSelector((state) => state.template.layout, []);
  const anuncio = useSelector((state) => state.anuncio.anuncio);
  const unidades = useSelector((state) => state.template.unidades, []);

  const getFavIcon = (size, logoUrl) => {
    return buildUrl({
      src: logoUrl,
      width: size,
      height: size,
      resizeType: "fit",
    });
  };

  const getFirstWhatsappFromList = (info) => {
    let whatsappPhone = info.telefones
      ? info.telefones.filter((phone) => phone.isWhatsapp)[0]
      : {};

    return whatsappPhone ? whatsappPhone.numero : null;
  };

  useEffect(() => {
    if (layout && !layout.template) {
      const urlParams = getParams(window.location.href);
      const name = getClientSiteName(window.location.pathname);

      if (urlParams.params) {
        setProtect(true);
      }

      dispatch(
        fetchGaragemLayout({
          hostName: window.location.hostname,
          nome: name,
          params: urlParams.params,
        }),
      );
    }

    const id = getLastArrayPosition(window.location.pathname.split("-"));
    dispatch(fetchAnuncioById(id));
    setAnuncioId(id);
  }, []);

  return (
    <>
      {layout && (
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            href={getFavIcon("32", layout.logo)}
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href={getFavIcon("76", layout.logo)}
          />
        </Helmet>
      )}

      <Topbar
        type={layout.template === "lista" ? "simple" : "with-logo"}
        backgroundColor={layout.cor_principal}
        textColor={layout.cor_texto}
        textColorDestaque={layout.cor_texto_ativo}
        logo={layout.logo}
        bannerDesktop={layout.banner_desktop}
        bannerMobile={layout.banner_mobile}
      />

      {anuncio && (
        <AnuncioContainer>
          <AnuncioResumo>
            <ResumoContainer>
              <div className="left-side">
                <Gallery imageList={anuncio.imagens} />

                <Hidden smUp>
                  <AnuncioTitleContainer>
                    <p className="title">{anuncio.titulo}</p>
                    <p className="subtitle">
                      {anuncio.veiculoVersao.descricao}
                    </p>

                    <p className="price">
                      {!anuncio.valorCombinar ? (
                        <Currency quantity={anuncio.valor} currency="BRL" />
                      ) : (
                        "Valor a Combinar"
                      )}
                    </p>
                    <p className="codigo">
                      {anuncio.codigo ? `Cód. ${anuncio.codigo}` : ""}
                    </p>
                  </AnuncioTitleContainer>
                </Hidden>

                <AnuncioInfo>
                  {anuncio.anoVersao && (
                    <li>
                      <p className="title">Ano</p>
                      <p className="value">{`${anuncio.anoFabricacao}/${anuncio.anoVersao}`}</p>
                    </li>
                  )}

                  {anuncio.quilometragem && (
                    <li>
                      <p className="title">Km</p>
                      <p className="value">{anuncio.quilometragem} km</p>
                    </li>
                  )}

                  {anuncio.tipoCambio && (
                    <li>
                      <p className="title">Câmbio</p>
                      <p className="value">{anuncio.tipoCambio.descricao}</p>
                    </li>
                  )}

                  {anuncio.tipoCombustivel && (
                    <li>
                      <p className="title">Combustível</p>
                      <p className="value">
                        {anuncio.tipoCombustivel.descricao}
                      </p>
                    </li>
                  )}

                  {anuncio.corVeiculo && (
                    <li>
                      <p className="title">Cor</p>
                      <p className="value">{anuncio.corVeiculo.descricao}</p>
                    </li>
                  )}
                </AnuncioInfo>
              </div>

              <div className="right-side">
                <Hidden smDown>
                  <AnuncioTitleContainer>
                    <p className="title">{anuncio.titulo}</p>
                    <p className="subtitle">
                      {anuncio.veiculoVersao.descricao}
                    </p>

                    <p className="price">
                      {!anuncio.valorCombinar ? (
                        <Currency quantity={anuncio.valor} currency="BRL" />
                      ) : (
                        "Valor a Combinar"
                      )}
                    </p>
                    <p className="codigo">
                      {anuncio.codigo ? `Cód. ${anuncio.codigo}` : ""}
                    </p>
                  </AnuncioTitleContainer>
                </Hidden>

                <Contato
                  anuncioId={anuncioId}
                  textColor={layout.cor_texto_botao}
                  buttonColor={layout.cor_secundaria}
                  titulo={anuncio.titulo}
                  telefoneList={
                    layout.enderecoPrincipal
                      ? layout.enderecoPrincipal.telefones
                      : null
                  }
                  whatsapp={
                    layout.enderecoPrincipal
                      ? getFirstWhatsappFromList(layout.enderecoPrincipal)
                      : null
                  }
                />
              </div>
            </ResumoContainer>
          </AnuncioResumo>

          {anuncio.descricao && (
            <AnuncioSection>
              <SectionTitle>Observações do vendedor</SectionTitle>
              <SectionText
                dangerouslySetInnerHTML={{ __html: anuncio.descricao }}
              />
            </AnuncioSection>
          )}

          {anuncio.caracteristicas && (
            <AnuncioSection>
              <SectionTitle>Detalhes</SectionTitle>
              <SectionList>
                {anuncio.caracteristicas.map((caracteristica) => (
                  <li key={`caracteristica-${caracteristica.id}`}>
                    {caracteristica.descricao}
                  </li>
                ))}
              </SectionList>
            </AnuncioSection>
          )}

          {anuncio.finalPlaca && (
            <AnuncioSection>
              <SectionTitle>Outros detalhes</SectionTitle>
              <SectionList>
                <li>Final da placa: {anuncio.finalPlaca}</li>
                <li>Quantidade de portas: {anuncio.qtdPortas}</li>
              </SectionList>
            </AnuncioSection>
          )}
        </AnuncioContainer>
      )}

      <br />
      <br />

      {layout && layout.redesSociais && (
        <RedesSociais redes={layout.redesSociais} />
      )}

      {unidades && (
        <Unidades
          lista={unidades}
          backgroundColor={layout.cor_principal}
          textColor={layout.cor_texto}
        />
      )}
      <Copyright
        backgroundColor={layout.cor_principal}
        textColor={layout.cor_texto}
      />
    </>
  );
};

export const loadData = ({ store, match, req }) => {
  const fullUrl = req.get("host");
  const nome = req.originalUrl.split("/")[1] || null;

  return store.dispatch(fetchGaragemLayout({ hostName: fullUrl, nome: nome }));
};

export default Anuncio;

